
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import '../styles/utils.css'
import { useLoaderData } from "react-router-dom";

import main, { formatCash, _DateTime, format_cash} from '../resources/code/utils'

import Fade from '@mui/material/Fade'
import ProfileImage from '../resources/icons/profile.png'
import { useOutletContext } from "react-router-dom";
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';


export function loader({params}){
    console.log(params)
    const parameters = params.parameters.split('&')
    
    const rules = {}
    for(const param of parameters){
        const s = param.split('=')
        if(s.length === 2){
            rules[s[0]] = s[1]
        }
    }

    console.log(rules)

    return rules
}  

export default function SellPellets(){
    
    const [rules,  ] = useState(useLoaderData())

    const [ tab, setTab ] = useState(1)

    const [start, setStart] = useState(false)
    const [shop, setShop] = useState('')
    const [ shop_uid, setShopUID ] = useState('')
    const [amount, setAmount] = useState('')
  
    const [setSnack, setAlert, username,  ] = useOutletContext()
    const [ sale_date, setSaleDate ] = useState(new Date()) 

   
    const [branch_list, setBranchList] = useState([])
    const [description, setDescription ] = useState('')
    const [debts, setDebts] = useState([])
    const [ client_pellet_debts , setClientPelletDebts] = useState([])

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'


    const [ selected_client, setSelectedClient ]  = useState(false)
    const [ clientSearchRes, setClientSearchRes ] = useState([])
    const [ total, setTotal ]  = useState(0)
    const [ payment, setPayment ]  = useState(0)
    const [ pellet_payment, setPelletPayment ]  = useState(0)
    const [ stove_payment, setStovePayment ] = useState(0)
    const [ store_commission ,setStoreCommission ] = useState(0)
    const [ pay_store_commission, setPayStoreCommission ] = useState(true)

   
    const [client_serach, setClientSearch ]  = useState('')


    const [ amount_to_pay_debt, setAmountToPay ] = useState('')
    const [ pay_debt_client, setPayDebtClient ] = useState([])
    const [ debtClientSearchRes, setDebtClientSearchRes ] = useState([])
    const [ debt_payment_date, setDebtPaymentDate ] = useState(new Date())
    const [client_search_debt, setClientSearchDebt ]  = useState('')

    function handleStoreCommissionPayment(){
        setPayStoreCommission(prev => !prev)
    }

    function Update(elm, updater, type){
        const value = elm.target.value
        
        if(type === phone_number){
            if(Number(value) || value === '' || value === '0'){
                updater(value)
            }    
        }if(type === number){
            if(Number(value) || value === '' || value === '0'){
                updater(Number(value))
            }    
        }
        else if (type === text){
            updater(value)
        }
    }

    const required_inputs = [
        {input: shop},
        {input: amount},
        {input: selected_client}
    ]

    function validate_pelletRecordForm(required_inputs){
        let incomplete = false
        for(const input of required_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }
    


  
   


    function searchForBranches(elm){
        setShop(elm.target.value)
      
        fetch(main.server + `/shops?q=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setBranchList(res.data.shops)
            }
        })
    }

    function selectBranch(name, uid){
        setShop(name)
        setShopUID(uid)
 
        setBranchList([])
    }


    function recordPelletSales(){
        if(!validate_pelletRecordForm(required_inputs)){
            const form = new FormData()
            const f = {
                shop: shop_uid,
                amount: amount,
                payment: payment,
                client: selected_client[1],
                recorded_by: username,
                description:description,
                pay_store_commission: pay_store_commission,
                date: sale_date.toDateString(),
            }
         


            form.append('form', JSON.stringify(f))
        

            fetch(main.server + '/record_pellet_sales',{
                method: 'POST',
                body: form
            }, ).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'record succefully created',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/records'),5000)
                }
                
            })
        }
    }

    const required_inputs_pellet_payment = [
        //{input: pay_debt_client[1]},
        {input: amount_to_pay_debt},
        {input: debt_payment_date}
    ]
    
    function recordPelletPayment(){
        if(!validate_pelletRecordForm(required_inputs_pellet_payment) && pay_debt_client.length == 2 ){
            const form = new FormData()
            const f = {
               
                client: pay_debt_client[1],
                recorded_by: username,
                //description:description,
                amount: amount_to_pay_debt,
                date: debt_payment_date.toDateString(),
            }
         


            form.append('form', JSON.stringify(f))
        

            fetch(main.server + '/record_pellet_payment',{
                method: 'POST',
                body: form
            }, ).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'debt succefully payed',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/records'),5000)
                }
                
            })
        }
    }



    



    function searchForClient(event){
      
        const value = event.target.value
        setClientSearch(value)

       
        if(event.target.value === ''){
            setClientSearchRes([])
            return
        }

        fetch(main.server + `/clients?client=${value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setClientSearchRes(res.data.client_list)
                setSelectedClient(null)
                
            }
        })
            
    }

    function searchForClientDebt(event){
      
        const value = event.target.value
        setClientSearchDebt(value)
        
        if(event.target.value === ''){
            setDebtClientSearchRes([])
            return
        }

        fetch(main.server + `/clients?client=${value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDebtClientSearchRes(res.data.client_list)
                setPayDebtClient(null)
            }
        })
            
    }

    function selectClient(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        setSelectedClient([name, uid])
        setClientSearchRes([])

        setClientSearch(name)
        fetch(main.server + `/get_client_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setDebts(result.data.debts)
        })
      
    }


    function selectDebtClient(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        setPayDebtClient([name, uid])
        setDebtClientSearchRes([])

        setClientSearchDebt(name)
        fetch(main.server + `/get_client_pellet_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setClientPelletDebts(result.data.debts)
        })
      
    }


  
    function handleAmount(event){
        let value = event.target.value
      
        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if(Number(clean) || clean === '' || clean === '0'){
                
                clean = Number(clean)
                setTotal(clean * 700)
                setPayment(clean * 700)
                setAmount(clean)
                setPelletPayment(clean * 450)
                setStovePayment(clean * 200)
                setStoreCommission(clean * 50)
            }
        }else{
            if(Number(value) || value === '' || value === '0'){
                value = Number(value)
                setTotal( value * 700)
                setPayment(value * 700)
                setAmount(value)
                setPelletPayment( value * 450)
                setStovePayment( value * 200)
                setStoreCommission(value * 50)
            } 
        } 
    }


    const pellet_split = 71
    const stove_split = 29



    function RoundDown(value){
        return Math.floor(value / 100) * 100
    }

    function calculateSplit(){
        let avail = payment
        let t = total
        if(pay_store_commission){
            avail -= store_commission
            t -= store_commission
            setStoreCommission(store_commission)
        }

        console.log(avail, t)
        if(avail < t){
            let pellet_payment = RoundDown(avail * pellet_split/100)
            let stove_payment = RoundDown(avail * stove_split/100)

            const rem = avail - (pellet_payment + stove_payment)
            pellet_payment += rem
            
            console.log('list ==================>',avail,pellet_payment, stove_payment, rem)

            setPelletPayment( pellet_payment)
            setStovePayment( stove_payment)
        
        }

        if(avail === t){
            
            console.log('equal to total')
            let pellet_payment = amount * 450
            let stove_payment = amount * 200
            let commision =  amount * 50 

            if (!pay_store_commission){
                pellet_payment += commision
            }else{
                setStoreCommission(commision)
            }
            setPelletPayment( pellet_payment )
            setStovePayment( stove_payment)
        
        }
    }
    function handleAdvance(event){
        let value = event.target.value
       



        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if((Number(clean) || clean === '' || clean === '0' ) && Number(clean) <= total){
                
                clean = Number(clean)
                setPayment(clean)
            
                
            }
        }else{
            if((Number(value) || value === '' || value === '0') && Number(value) <= total){
                value = Number(value)
                setPayment(value)
              
            } 
        } 
    }
    

    function handleDebtAmount(event){
        const val = event.target.value
        if(Number(val) || val  === ''){
            setAmountToPay(val)  
        }
    }

    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setClientSearchRes([])
            setBranchList([])
        }
        
    }




    function handleTabChange(event, new_value){

        setTab(new_value)
    }


    useEffect(() => {
        if(!start){
            setSnack({
                message: 'page loaded',
            })
    
            if(rules.shop){
                setShop(rules.shop)
            }
           
            if(rules.client_name){
                console.log(rules)
                setSelectedClient([rules.client_name, rules.clientID])
                setClientSearch(rules.client_name)
            }
            setStart(true)
        }
        
    })

    useEffect(() => {
        calculateSplit()
    },[pay_store_commission, payment])
    return(
        <Fade in={true}>
            <div className="page" onClick={handleClicks}>
                <div className="system_title">
                   <div>Record Shop Pellet Sales</div>
                </div> 

                <div className="centerPage">





                    <div className="tabs">
                        <Tabs className='tab_container'  value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab value={1} label="Record pellet sales"  />
                            <Tab value={2} label="Pay debts"  />
                        
                        
                        </Tabs>
                    </div>

                    { tab === 1 ? <div>
                        
                        { debts.length ? 
                            <div className='clientDebt pad20'>
                                <div>
                                    <div className="warning_text inline_heading bold500">warning</div>
                                    <div className='warning_text'>
                                        <div>this client is owed money from the following stove sales</div>
                                        <div>money from this purchase will be used to pay for the stove debt</div>
                                    </div>
                                
                                    {debts.length ?
                                        <div className="padv20">
                                            <div>Debts: </div>
                                            <div>
                                                {debts.map((elm, index) => {
                                                    
                                                    
                            

                                                    return (
                                                        <div className='accordionItem' key={index}>
                                                            <Accordion >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                >
                                                                    <Typography>Stove serial number: {elm.stoveID}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                
                                                                
                                                                    <div className="info_value_pair">
                                                                        <div>amount owed: </div>
                                                                        <div>{formatCash( elm.price )}</div>
                                                                    </div>
                                                                    <div className="info_value_pair">
                                                                        <div>amount paid: </div>
                                                                        <div>{formatCash( elm.amount_paid )}</div>
                                                                    </div>
                                                                    <div className="info_value_pair">
                                                                        <div>balance: </div>
                                                                        <div>{formatCash( Number(elm.price) - Number(elm.amount_paid) )}</div>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )
                                                    
                                                })}
                                            </div>
                                        </div>: ''
                                    }
                                </div>
                            </div>
                        :''}
                        <div className='centerPage'>

                            
                            <div className="form_main form_centered">
                                

                                <div className="form_section">
                                
                                    <div className="form_section_main">
                                    
                                        <div>
                                            <div className="authenticatorTitle">Recorded by:</div>
                                            <div>
                                                <div className="authenticator_final" >
                                                    
                                                    <div className="flex_row flex_align_center">
                                                        <div className="authenticator_avatar">
                                                            <img src={ProfileImage} alt="user image"/>
                                                        </div>
                                                        <div className="padh10">{username}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div>
                                        
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date of sale"
                                                    value={sale_date}
                                                    variant="standard"
                                                    onChange={(newstartDate) => {
                                                        setSaleDate(newstartDate)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div>
                                            <TextField value={shop} onChange={searchForBranches} sx={{width: 250}} className="text_field" id="standard-basic" label="Center" variant="standard" />


                                            <div className="filterSearchRes">
                                                {branch_list.length > 0 ?
                                                    <Paper elevation={1} className="searchRBody searchSys">
                                                        {branch_list.map((elm,ind) => (
                                                            <div className="generalSearchResult searchSys" data-uid={elm.UID} onClick={() => selectBranch(elm.name, elm.UID)} key={ind}>
                                                                {elm.name}
                                                            </div>
                                                        ))}
                                                    
                                                    </Paper>
                                                :''}

                                                
                                            
                                            </div>

                                        
                                        </div>

                                        <div>                              
                                            <div><TextField value={client_serach} onChange={searchForClient} sx={{width: 250}} className="text_field" id="standard-basic" label="client" variant="standard" /></div>

                                            <div className="filterSearchRes">
                                                {clientSearchRes.length > 0 ?
                                                    <Paper elevation={1} className="searchRBody">
                                                        {clientSearchRes.map((elm,ind) => (
                                                            <div className="generalSearchResult" data-uid={elm.UID} onClick={selectClient} key={ind}>
                                                                {elm.name}
                                                            </div>
                                                        ))}
                                                    
                                                    </Paper>
                                                :''}

                                                
                                            
                                            </div>
                                        
                                        </div>
                                        
                                        <div>
                                            <div><TextField value={format_cash(amount)} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="amount in kg" variant="standard" /></div>
                                        </div>

                                        
                                    </div>
                                </div>

                                <div className="form_section">
                                    <div className="form_section_main">

                                        
                                        <div>
                                            <div><TextField disabled value={format_cash(total)} sx={{width: 250}}  className="text_field" id="standard-basic" label="value" variant="standard" /></div>
                                        </div>

                                        <div>
                                            <div><TextField value={format_cash(payment)} sx={{width: 250}}  onChange={handleAdvance} className="text_field" id="standard-basic" label="amount paid" variant="standard" /></div>
                                        </div>

                                        {debts.length > 0 ? 
                                            <div>
                                                <div className='padv20'>
                                                    <div><TextField disabled={true} value={pellet_payment} sx={{width: 250}}  className="text_field" id="standard-basic" label="pellet payment" variant="standard" /></div>
                                                </div>
                                                <div className='padv20'>
                                                    <div><TextField disabled={true} value={stove_payment} sx={{width: 250}}  className="text_field" id="standard-basic" label="stove payment" variant="standard" /></div>
                                                </div>
                                            </div>
                                        :''}
                                        <div>
                                            <div className="padv20">
                                                pay store commission <Checkbox checked={pay_store_commission} onChange={handleStoreCommissionPayment}/> 
                                            </div>
                                            { pay_store_commission ? <div><TextField disabled={true} value={store_commission} sx={{width: 250}}  className="text_field" id="standard-basic" label="store commision" variant="standard" /></div> : '' }
                                        </div>
                                    </div>
                                </div>

                                <div className="form_section">
                        
                                    <div className="form_section_main">
                                        
                                        
                                        
                                        



                                        


                                        <div>
                                            <TextField
                                                sx={{width: 250}}
                                                className="text_field"
                                                id="standard-multiline-static"
                                                label="Record description"
                                                multiline
                                                rows={4}
                                            
                                                value={description}
                                                variant="standard"
                                                onChange={(elm) => Update(elm, setDescription, text)}
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                        
                            
                            
                            </div>
                            
                            <div className="uni_procced_btn">
                                <div className="btn_filled" onClick={recordPelletSales}>
                                    <div>
                                        process pellet sale
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    :
                    <div className="centerPage">

                        {client_pellet_debts.length ? 
                            <div className='clientDebt pad20'>
                       
                                {client_pellet_debts.length ?
                                    <div className="padv20">
                                        <div>Debts: </div>
                                        <div>
                                            {client_pellet_debts.map((elm, index) => {
                                                const debt = Number(elm.stove_debt) + Number(elm.pellet_debt) + Number(elm.commission_debt)
                                                const paid = Number(elm.stove_payment) + Number(elm.pellet_payment) + Number(elm.store_commission)


                                                return (
                                                    <div className='accordionItem'>
                                                        <Accordion >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                            >
                                                                <Typography>Pellet debt</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                            
                                                            
                                                                <div className="info_value_pair">
                                                                    <div>amount owed: </div>
                                                                    <div>{formatCash(debt)}</div>
                                                                </div>
                                                                <div className="info_value_pair">
                                                                    <div>amount paid: </div>
                                                                    <div>{formatCash(paid)}</div>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>: ''
                                }
                           
                            </div>
                        :''}

                        <div className="form_main form_centered">   
                            <div className="form_section">    
                                <div className="form_section_main">
                                    <div>
                                        <div className="authenticatorTitle">Recorded by:</div>
                                        <div>
                                            <div className="authenticator_final" >
                                                
                                                <div className="flex_row flex_align_center">
                                                    <div className="authenticator_avatar">
                                                        <img src={ProfileImage} alt="user image"/>
                                                    </div>
                                                    <div className="padh10">{username}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div>
                                    
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Date of sale"
                                                value={debt_payment_date}
                                                variant="standard"
                                                onChange={(newstartDate) => {
                                                    setDebtPaymentDate(newstartDate)
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div>                              
                                        <div><TextField value={client_search_debt} onChange={searchForClientDebt} sx={{width: 250}} className="text_field" id="standard-basic" label="client" variant="standard" /></div>

                                        <div className="filterSearchRes">
                                            {debtClientSearchRes.length > 0 ?
                                                <Paper elevation={1} className="searchRBody">
                                                    {debtClientSearchRes.map((elm,ind) => (
                                                        <div className="generalSearchResult" data-uid={elm.UID} onClick={selectDebtClient} key={ind}>
                                                            {elm.name}
                                                        </div>
                                                    ))}
                                                
                                                </Paper>
                                            :''}

                                            
                                        
                                        </div>
                                    
                                    </div>

                                    <div>
                                        <div><TextField value={amount_to_pay_debt} onChange={handleDebtAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="amount" variant="standard" /></div>
                                    </div>


                                    <div className="uni_procced_btn">
                                        <div className="btn_filled" onClick={recordPelletPayment}>
                                            <div>
                                                pay debt
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> 

                    </div>
                    }

                </div>
            </div>
        </Fade>
    )
}